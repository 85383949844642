import { Permission } from '@juristat/common/accounts'
import config from '@juristat/config'
import { useMachine } from '@xstate/react'
import decode from 'jwt-decode'
import React, { useCallback, useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useInterval } from '../../../hooks/useInterval'
import { getPathname } from '../../router'
import sessionActions from '../../session/actions'
import actions from '../actions'
import {
  AccessTokenContext,
  Auth0ClientContext,
  AuthorizeContext,
  GroupContext,
  ResetPasswordContext,
  UserContext,
} from '../contexts'
import { resetPasswordMachine } from '../machines'
import { getAccessTokenId } from '../selectors'
import { AuthProvider, AuthorizeResponse } from '../types'
import { auth0Client } from '../utils/auth0'

type State = {
  accessToken: string
  delay: number | null
  group: {
    name: string
    uuid: string
  } | null
  user: {
    email: string
    firstName: string
    lastName: string
    uuid: string
  } | null
}

type Action = { type: 'set'; data: { [K in keyof State]: NonNullable<State[K]> } }

type Claim = 'active_group' | 'active_group_name' | 'email' | 'family_name' | 'given_name' | 'uuid'

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'set':
      return action.data
    default:
      return state
  }
}

function useResetPassword() {
  const [current, send] = useMachine(resetPasswordMachine, {
    services: {
      reset: (_context, event) =>
        new Promise<void>((resolve, reject) => {
          try {
            auth0Client.changePassword(
              {
                connection: config.auth0JuristatAccountsConnection,
                email: event.email,
              },
              (error) => {
                if (error) {
                  return reject(error.error)
                }

                resolve()
              }
            )
          } catch (unknownErr) {
            const error = unknownErr as Error & { description?: string }
            reject(error.description ?? 'An error occurred resetting your password.')
          }
        }),
    },
  })
  const pathname = useSelector(getPathname)

  const resetPassword = useCallback(
    (email: string) => {
      send('FETCH', { email })
    },
    [send]
  )

  useEffect(() => {
    if (!current.matches('idle')) {
      send('RESET')
    }
  }, [pathname])

  return [current, resetPassword] as const
}

function noAuthNeeded(pathname: string) {
  return pathname.startsWith('/confirm-email') || pathname.startsWith('/signup')
}

function getPermissionsFromScope(scopes: string[]) {
  const permissions = scopes.reduce((acc, item) => {
    acc[item.replace('legacy:', '')] = true

    return acc
  }, {})

  return {
    accountTools: permissions[Permission.CanUseTools] || false,
    alpha: permissions[Permission.Alpha] || false,
    analystMetrics: permissions[Permission.AnalystMetrics] || false,
    api: permissions[Permission.Api] || false,
    assignmentSheet: permissions[Permission.AssignmentSheet] || false,
    bi: permissions[Permission.Bi] || false,
    charts: permissions[Permission.Charts] || false,
    clientConfig: permissions[Permission.CanViewOarClientConfig] || false,
    clientUsageReport: permissions[Permission.CanViewOarClientUsageReport] || false,
    confidentialData: permissions[Permission.CanViewConfidentialData] || false,
    customerPpairSettings: permissions[Permission.CanAdministerPpairWhitelist] || false,
    drafting: permissions[Permission.Drafting] || false,
    editOarTimes: permissions[Permission.CanEditTimes] || false,
    examiner: permissions[Permission.Examiner] || false,
    expertSearch: permissions[Permission.ExpertSearch] || false,
    expertSearchExport: permissions[Permission.ExpertSearchExport] || false,
    exportAnalytics: permissions[Permission.ExportAnalytics] || false,
    freeHealthDashboard: permissions[Permission.FreeHealthDashboard] || false,
    groupAdmin: permissions[Permission.GroupAdmin] || false,
    groupOwner: permissions[Permission.GroupOwner] || false,
    humanTasks: permissions[Permission.HumanTasks] || false,
    ids: permissions[Permission.Idss] || false,
    idsAssignmentSheet: permissions[Permission.IdsAssignmentSheet] || false,
    idsViewer: permissions[Permission.IdsViewer] || false,
    oarBuild: permissions[Permission.CanOarBuild] || false,
    oarBuildQuality: permissions[Permission.OarBuildQuality] || false,
    oarReview: permissions[Permission.CanOarReview] || false,
    oarReviewQuality: permissions[Permission.OarReviewQuality] || false,
    oarSender: permissions[Permission.OarSender] || false,
    oarTech: permissions[Permission.CanOarTech] || false,
    oarViewer: permissions[Permission.OarViewer] || false,
    oneOTwoReports: permissions[Permission.OneOTwoReports] || false,
    personal: permissions[Permission.Personal] || false,
    platformExaminerAuTcFilters: permissions[Permission.PlatformExaminerAuTcFilters] || false,
    platformFirmAssigneeFilters: permissions[Permission.PlatformFirmAssigneeFilters] || false,
    platformOaRceCountFilters: permissions[Permission.PlatformOaRceCountFilters] || false,
    platformPatentFamilyComponent: permissions[Permission.PlatformPatentFamilyComponent] || false,
    platformPremiumSorts: permissions[Permission.PlatformPremiumSorts] || false,
    platformRegCustNumFilters: permissions[Permission.PlatformRegCustNumFilters] || false,
    platformSavedSearchAndHistory: permissions[Permission.PlatformSavedSearchAndHistory] || false,
    platformSearchScopes: permissions[Permission.PlatformSearchScopes] || false,
    platformThreePaneView: permissions[Permission.PlatformThreePaneView] || false,
    ppair: permissions[Permission.PrivatePair] || false,
    premiumWork: permissions[Permission.PremiumWork] || false,
    rejectionBasisFilter: permissions[Permission.RejectionBasisFilter] || false,
    rejections: permissions[Permission.RejectionView] || false,
    selfAnalystMetrics: permissions[Permission.SelfAnalystMetrics] || false,
    smartshell: permissions[Permission.TurboPatentRejectionLink] || false,
    staffSettings: permissions[Permission.StaffSettings] || false,
    table: permissions[Permission.Table] || false,
  }
}

const Auth0ContextProvider = ({ children }: { children: React.ReactNode }) => {
  const reduxDispatch = useDispatch()
  const accessTokenId = useSelector(getAccessTokenId)
  const { pathname } = useLocation()
  const [{ accessToken, delay, group, user }, dispatch] = useReducer(reducer, {
    accessToken: '',
    delay: null,
    group: null,
    user: null,
  })

  const checkSession = useCallback(() => {
    if (noAuthNeeded(pathname)) {
      return
    }

    try {
      auth0Client.checkSession({}, (error, result: AuthorizeResponse) => {
        if (error) {
          return reduxDispatch(actions.hydrateAccessTokenError(error.error))
        }

        const { accessToken, expiresIn, scope } = result
        const claims = decode<Record<`https://juristat.com/${Claim}`, string>>(accessToken)

        reduxDispatch(
          sessionActions.set({
            firstName: claims['https://juristat.com/given_name'],
            groupId: claims['https://juristat.com/active_group'],
            lastName: claims['https://juristat.com/family_name'],
            permissions: getPermissionsFromScope(scope.split(' ')),
            username: claims['https://juristat.com/email'],
            uuid: claims['https://juristat.com/uuid'],
          })
        )

        reduxDispatch(actions.setAccessToken(accessToken, { expiresIn }))

        dispatch({
          data: {
            accessToken,
            delay: (expiresIn - 60) * 1000,
            group: {
              name: claims['https://juristat.com/active_group_name'],
              uuid: claims['https://juristat.com/active_group'],
            },
            user: {
              email: claims['https://juristat.com/email'],
              firstName: claims['https://juristat.com/given_name'],
              lastName: claims['https://juristat.com/family_name'],
              uuid: claims['https://juristat.com/uuid'],
            },
          },
          type: 'set',
        })
      })
    } catch (unknownErr) {
      const error = unknownErr as Error & { description?: string; error?: string }
      reduxDispatch(actions.hydrateAccessTokenError(error.error))
    }
  }, [reduxDispatch, pathname])

  const authorize = useCallback(
    (connection: AuthProvider) => {
      if (noAuthNeeded(pathname)) {
        return
      }

      try {
        auth0Client.authorize({ connection })
      } catch (unknownErr) {
        const error = unknownErr as Error & { description?: string }
        reduxDispatch(actions.error(error.description ?? 'Failed to authorize', { debug: error }))
      }
    },
    [reduxDispatch, pathname]
  )

  useInterval(checkSession, delay)
  useEffect(checkSession, [accessTokenId])

  return (
    <Auth0ClientContext.Provider value={auth0Client}>
      <AuthorizeContext.Provider value={authorize}>
        <ResetPasswordContext.Provider value={useResetPassword()}>
          <GroupContext.Provider value={group}>
            <UserContext.Provider value={user}>
              <AccessTokenContext.Provider value={accessToken}>
                {children}
              </AccessTokenContext.Provider>
            </UserContext.Provider>
          </GroupContext.Provider>
        </ResetPasswordContext.Provider>
      </AuthorizeContext.Provider>
    </Auth0ClientContext.Provider>
  )
}

export default Auth0ContextProvider
