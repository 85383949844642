import { useContext, useEffect } from 'react'

import { useGroup, useUser } from '../auth'
import { IntercomActionsContext } from './contexts'

type IntercomApi = (verb: string, opts: Record<string, unknown>) => void

type PendoApi = {
  initialize(options: {
    account: { [key: string]: string }
    visitor: { [key: string]: string }
  }): void
}

declare global {
  interface Window {
    Intercom?: IntercomApi
    pendo?: PendoApi
  }
}

export function useIntercomActions() {
  const context = useContext(IntercomActionsContext)

  if (context === undefined) {
    throw new Error('useIntercomActions must be used within a IntercomActionsContext')
  }

  return context
}

export function useIntercom() {
  const user = useUser()
  const group = useGroup()

  useEffect(
    function initializeIntercom() {
      if (!user || !group) {
        return
      }

      const { email, firstName, lastName, uuid } = user

      window.Intercom?.('update', {
        company: {
          emailDomain: email.split('@').pop(),
          id: group.uuid,
        },
        email,
        logrocketURL: `https://app.logrocket.com/${process.env.LOGROCKET_ID}/sessions?u=${uuid}`,
        name: `${firstName} ${lastName}`,
        user_id: uuid,
      })
    },
    [user]
  )
}

export function usePendo() {
  const user = useUser()

  useEffect(
    function initializePendo() {
      if (!user) {
        return
      }

      const { email, firstName, lastName, uuid } = user

      window.pendo?.initialize({
        visitor: {
          id: uuid,
          email,
          firstName,
          lastName,
        },

        account: {
          id: uuid,
        },
      })
    },
    [user]
  )
}
